import React, { useState } from 'react';
import { Button } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Slide {
  image: string;
  title: string;
  text: string;
  btnAnterior: string;
  btnSiguiente: string;
}

interface SlideRecognitionProps {
  onRecognition: () => void;
}

const SlideRecognition: React.FC<SlideRecognitionProps> = ({ onRecognition }) => {
	const [slider, setSlider] = useState<Slider | null>(null);
	const [isSliderAvailable, setIsSliderAvailable] = useState<boolean>(false);

	const handleSliderPrev = () => {
		slider && slider.slickPrev();
	};

	const handleSliderNext = () => {
		slider && slider.slickNext();
	};

	const slides: Slide[] = [
		{
			image: '/assets/tip1.png',
			title: 'Tips:',
			text: 'Quítate gorros, lentes y bufandas',
			btnAnterior: 'Anterior',
			btnSiguiente: 'Siguiente',
		},
		{
			image: '/assets/tip2.png',
			title: 'Tips:',
			text: 'Busca un lugar iluminado',
			btnAnterior: 'Anterior',
			btnSiguiente: 'Siguiente',
		},
		{
			image: '/assets/tip3.png',
			title: 'Tips:',
			text: 'Trata de no tener barbijo',
			btnAnterior: 'Anterior',
			btnSiguiente: 'Siguiente',
		},
		{
			image: '/assets/tip4.png',
			title: 'Tips:',
			text: 'Tú solo debes estar en la cámara',
			btnAnterior: 'Anterior',
			btnSiguiente: 'Lo Tengo',
		},
	];

	return (
		<div >
			<div >
				<Slider
					ref={(slider) => {
						setSlider(slider);
						setIsSliderAvailable(!!slider);
					}}
					dots
					autoplay
					autoplaySpeed={2000}
					infinite
					slidesToShow={1}
					slidesToScroll={1}
					centerMode={true}
					centerPadding="0"
				>
					{slides.map((slide, index) => (
						<div key={index}>
							<img src={slide.image} alt={slide.title} />
							<h2>{slide.title}</h2>
							<p>{slide.text}</p>
							<div className="button-container">
								{index < 3 && (
									<>
										<Button variant="text" color="secondary" onClick={handleSliderPrev} disabled={!isSliderAvailable}>
											{slide.btnAnterior}
										</Button>
										<Button variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />} onClick={handleSliderNext} disabled={!isSliderAvailable}>
											{slide.btnSiguiente}
										</Button>
									</>
								)}
								{index === 3 && (
									<>
										<Button variant="text" color="secondary" onClick={handleSliderPrev} disabled={!isSliderAvailable}>
											{slide.btnAnterior}
										</Button>
										<Button variant="contained" color="primary" onClick={onRecognition}>
											{slide.btnSiguiente}
										</Button>
									</>
								)}
							</div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default SlideRecognition;
