import React, { useEffect, useState } from "react";
import CameraPermissions from "../../components/FaceRecognition/CameraPermissions";
import WebcamViewer from "../../components/FaceRecognition/CamViewer";
import SlideRecognition from "../../components/FaceRecognition/SlideRecognition";
import "./RecognitionScreen.css";
import GoBackButton from "components/FaceRecognition/GoBackButton";
import SkipButton from "components/FaceRecognition/SkipButton";
import useRedirection from "hooks/useRedirection";
import interceptorAuth from "interceptorAuth";

type RecognitionScreenProps = {
	data: {
		userId: string;
  		clientId: string;
		redirect: string;
	}
};

const RecognitionScreen: React.FC<RecognitionScreenProps> = (props: RecognitionScreenProps) => {
	const [cameraEnabled, setCameraEnabled] = useState<boolean>(false);
	const [showWebcamViewer, setShowWebcamViewer] = useState<boolean>(
		JSON.parse(localStorage.getItem("skipTutorial") || "false")
	);
	const { generateURL } = useRedirection();
	console.log({ props })
	const handlePermissionGranted = () => {
		setCameraEnabled(true);
	};

	const handleRecognition = (save: boolean = false) => {
		setShowWebcamViewer(true);
		save && localStorage.setItem("skipTutorial", JSON.stringify(true));
	};

	const handldeGoBack = () => {

		const url = generateURL(
			props?.data?.redirect,
			400,
			false,
			"El usuario canceló la operación."
		);
		window.location.href = url;
	};

	return (
		<div className="container">
			{!cameraEnabled && (
				<CameraPermissions onPermissionGranted={handlePermissionGranted} />
			)}
			{cameraEnabled && !showWebcamViewer && (
				<div className="slide-container">
					<div className="slide-component">
						<SkipButton onClick={handleRecognition} />
						<SlideRecognition onRecognition={() => handleRecognition(true)} />
					</div>
				</div>
			)}

			<GoBackButton goBack={handldeGoBack} />

			{showWebcamViewer && cameraEnabled && (
				<WebcamViewer
					clientId={props?.data?.clientId}
					userId={props?.data?.userId}
				/>
			)}
		</div>
	);
};

export default interceptorAuth(RecognitionScreen);
