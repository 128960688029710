import React from 'react';
import {Button} from '@mui/material';
import './GoBackButton.css';

export type GoBackButtonProps = {
  goBack: (value?: any) => void;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ goBack }) => {
	return (
		<Button className="goBack" variant="outlined" color="secondary" onClick={goBack}>
			Ahora no puedo
		</Button>
	);
};

export default GoBackButton;
