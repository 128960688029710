import React from "react";
import "./CamViewer.css";
import styled from "@emotion/styled";
import useWebcamViewer from "./useWebcamViewer";

export type AlertErrorSeverityTypes = "success" | "error" | "warning" | "";
export type WebcamViewerProps = {
  clientId: string;
  userId: string;
};

type ContainerScaningSyledProps = { scanningVisible: boolean };

const ContainerScanningStyled = styled.div<ContainerScaningSyledProps>`
  margin-left: 100px;
  margin-top: 25px;
  width: 350px;
  height: "105px";
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 8px 27px -13px grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  visibility: ${(scanningVisible) => (scanningVisible ? "visible" : "hidden")};
`;
const WrapperImageStyled = styled.div`
  width: 50px;
  height: 50px;
  background-color: #e9e9e9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WebcamViewer: React.FC<WebcamViewerProps> = ({ clientId, userId }) => {
  const { videoRef, scanningVisible } = useWebcamViewer({ userId, clientId });

  return (
    <div className="CamViewer-container">
      <div className="CamViewer-section">
        <video ref={videoRef} autoPlay muted className="video"></video>
        <ContainerScanningStyled scanningVisible={scanningVisible}>
          <WrapperImageStyled>
            <img
              src="/assets/escanenado.png"
              alt="User Icon"
              style={{ width: "24px", height: "24px" }}
            />
          </WrapperImageStyled>
          <div>
            <div style={{ fontWeight: "bold" }}>Escaneando rostro</div>
            <div>Mantente de frente a la cámara.</div>
          </div>
        </ContainerScanningStyled>
      </div>
    </div>
  );
};

export default WebcamViewer;
