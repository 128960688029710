
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { decodeURLParams } from 'services/urldecoder';
import { sessionValidate } from './services/recognizeUser';
import useRedirection from 'hooks/useRedirection';
import { CircularProgress, Grid } from '@mui/material';

const interceptorAuth = (WrappedComponent: React.FC<any>) => {
	const InterceptorAuthComponent: React.FC = () => {
		// const navigate = useNavigate();
		const [data, setData] = useState<{ userId: string; clientId: string; redirect: string; } | null>(null);
		const { generateURL } = useRedirection();
		const { s, redirect } = decodeURLParams(window.location.search);

		useEffect(() => {
			const validation = async () => { 
				try {
					const res = await sessionValidate(s);
					const {
						user_id,
						client_id,
						/* custom_user_id */
					} = res?.data;
					setData({
						userId: user_id,
						clientId: client_id,
						redirect
					});
				} catch (error: any) {
					const url = generateURL(
						redirect,
						400,
						false,
						"Error en la validación de sesión. Favor de reintentar"
					);
					window.location.href = url;
				}
			}
			validation();
			
		}, []);

		if (data === null) {
			return (
				<Grid container display="flex" height="100vh" alignItems="center" justifyContent="center">
					<Grid item margin="auto">
						<CircularProgress  />
					</Grid>
				</Grid>
			)
		}

		return <WrappedComponent data={data} />;
	};

	return InterceptorAuthComponent;
};

export default interceptorAuth;
