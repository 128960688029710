import { useEffect, useState } from "react";
import { decodeURLParams } from "services/urldecoder";

const useRedirection = () => {
    const urlCliente = decodeURLParams(window.location.search);
    const [redirectUrl, setRedirectUrl] = useState<string>('');

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl]);

    /**
     * The function `redirectTo` takes a string parameter `to` and sets the redirect URL to that value
     * if `to` is truthy.
     * @param {string} to - The `to` parameter in the `redirectTo` function is a string that represents
     * the URL to which the user will be redirected.
     */
    const redirectTo = (to: string) => {
        to && setRedirectUrl(to);
    }

    /**
     * The function `generateURL` takes in a previous URL, status, success boolean, and message, and
     * returns a new URL with query parameters based on the input values.
     * @param {string} previusURL - The `previusURL` parameter is a string representing the base URL to
     * which query parameters will be appended.
     * @param {number} status - The `status` parameter is a number that represents the status of a
     * request or operation. It could be an HTTP status code or a custom status code indicating the
     * result of a process.
     * @param {boolean} success - The `success` parameter is a boolean value that indicates whether the
     * operation was successful or not. It is used in the `generateURL` function to generate a URL with
     * query parameters based on the status, success, and message values provided.
     * @param {string} message - The `message` parameter in the `generateURL` function is a string that
     * represents the message to be included in the generated URL. It is encoded using
     * `encodeURIComponent` before being added to the URL query parameters.
     * @returns The function `generateURL` returns a new URL constructed by appending query parameters
     * based on the input parameters `status`, `success`, and `message` to the `previusURL`.
     */
    const generateURL = (previusURL: string, status: number, success: boolean, message: string) => {
        const queryParams = new URLSearchParams({
            status: `${status}`,
            success: `${success}`,
            message: encodeURIComponent(message)
        }).toString();
        return `${previusURL}?${queryParams}`;
    }

    return {
        urlCliente,
        redirectTo,
        generateURL
    }
};

export default useRedirection;