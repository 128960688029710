import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Adobe Clean',
      'Roboto',
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',      
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '27.54px',
      color: '#4B4B4B',
    },
    h2: {
      fontWeight: 700,
      fontSize: '19px',
      lineHeight: '23.79px',
      color: '#505050',
    },
    h4:{
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '22.5px',
      color: '#1A1A1A',
    },
    body2:{
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20.3px',
      color: '#505050',
    }
    
  },
  palette: {
    secondary: {
      main: '#757575', 
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedSecondary': {
            borderRadius: '46px', 
            borderColor: '#747474', 
            borderWidth: '2px', 
            padding: '11px 16px', 
          },
          '&.MuiButton-containedPrimary': { 
            borderRadius: '46px', 
            padding: '11px 16px', 
          },
          borderRadius: '50px', 
          padding: '11px 16px', 
          margin: '8px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#DADCE0',
          marginTop:'15px',
          marginBottom:'25px',

        },
      },
    },
  },
});

export default theme;
