import React, { createContext, useContext, useState, ReactNode } from 'react';

interface UserContextType {
  username: string | null;
  setUsername: (username: string | null) => void;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [username, setUsername] = useState<string | null>(() => {
   
    return localStorage.getItem('username');
  });

  const contextValue = {
    username,
    setUsername: (newUsername: string | null) => {
     
      if (newUsername) {
        localStorage.setItem('username', newUsername);
      } else {
        localStorage.removeItem('username');
      }
      setUsername(newUsername);
    },
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};
