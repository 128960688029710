import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

interface CameraPermissionsProps {
  onPermissionGranted: () => void;
}

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;
const WrapperText = styled("div")`
  text-align: center;
  margin: 0 auto;
  max-width: 500px;
`;
const ImagePermissons = styled("img")`
  width: 10rem;
  height: 10rem;
`;

const CameraPermissions: React.FC<CameraPermissionsProps> = ({
  onPermissionGranted,
}) => {
  const [showNoAccess, setShowNoAccess] = useState<boolean>(false);
  const checkCameraPermissions = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        onPermissionGranted();
        setShowNoAccess(false);
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
      setShowNoAccess(true);
    }
  }, [onPermissionGranted]);

  useEffect(() => {
    const getPermissions = async () => {
      await checkCameraPermissions();
    };
    getPermissions();
  }, [checkCameraPermissions]);

  return (
    <Wrapper>
      <ImagePermissons
        src={`${
          showNoAccess
            ? "/assets/no-access-camera.svg"
            : "/assets/frontapp01.png"
        }`}
        alt="Front App"
      />
      <WrapperText>
        {showNoAccess && (
          <>
            <strong>Debes brindar permisos.</strong> <br />
            <Typography>
              {" "}
              Para poder escanear tu rostro debes permitirnos el acceso a tu
              cámara.{" "}
            </Typography>
          </>
        )}
        {!showNoAccess && (
          <>
            <strong>No tenemos acceso a tu cámara</strong> <br />
            <Typography>
              Revisa la configuracion del navegador y brinda los permisos para
              continuar.
            </Typography>
          </>
        )}
      </WrapperText>
    </Wrapper>
  );
};

export default CameraPermissions;
