import React from 'react';
import {Button} from '@mui/material';

interface SkipButtonProps {
  onClick: () => void;
}

const SkipButton: React.FC<SkipButtonProps> = ({ onClick }) => {
  return (
   <div style={{width:'100%', textAlign:'right'}}>
     <Button variant="text" color="secondary" sx={{float: ''}} onClick={onClick}>
      Skip
    </Button>
   </div>
  );
};

export default SkipButton;
