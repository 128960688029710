import { useCallback, useEffect, useRef, useState } from "react";
import { recognizeUser } from "services/recognizeUser";
import useRedirection from "hooks/useRedirection";

export type WebcamViewerHookProps = {
  userId: string;
  clientId: string;
};

const useWebcamViewer = ({ userId, clientId }: WebcamViewerHookProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [scanningVisible, setScanningVisible] = useState<boolean>(true);
  const { generateURL, redirectTo, urlCliente } = useRedirection();

  const dataURItoFile = useCallback((dataURI: string, fileName: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    return new File([blob], fileName, { type: "image/jpeg" });
  }, []);

  const captureFrame = useCallback(async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas?.toDataURL("image/png");
        const photo = dataURItoFile(dataURL, "photo.jpeg");
        setScanningVisible(false);
        try {
          const response = await recognizeUser(photo, userId, clientId);
          redirectTo(
            generateURL(
              urlCliente?.redirect,
              200,  
              response?.success || true,
              "Reconocimiento exitoso"
            )
          );
        } catch (error: any) {
          console.error("Error al reconocer imagen:", error?.data);
          redirectTo(
            generateURL(
              urlCliente?.redirect,
              500,
              false,
              "Esperando respuesta del servidor"
            )
          );
        }
      }
    }
  }, [
    videoRef,
    setScanningVisible,
    redirectTo,
    generateURL,
    urlCliente?.redirect,
    clientId,
    userId,
    dataURItoFile,
  ]);

  useEffect(() => {
    const current = videoRef.current;
    const enableCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (current) {
          current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
        redirectTo(
          generateURL(
            urlCliente?.redirect,
            400,
            false,
            "Error al acceder a la camara"
          )
        );
      }
    };

    enableCamera();

    const captureAndCheck = async () => {
      await captureFrame();
    };

    const cTimeout = setTimeout(() => {
      captureAndCheck();
    }, 2000);

    return () => {
      /* Al finalizar el componente va cortar el capturar la imagen */
      if (current && current.srcObject) {
        const stream = current.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      clearTimeout(cTimeout);
    };
  }, [captureFrame, generateURL, redirectTo, urlCliente]);

  const handleRetryRecognition = async () => {
    setScanningVisible(true);
    await captureFrame();
    console.log("retry recognizing...");
  };

  return {
    videoRef,
    handleRetryRecognition,
    scanningVisible,
  };
};
export default useWebcamViewer;
