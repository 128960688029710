import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import './global.css';
import { ThemeProvider } from '@mui/material/styles';
import { UserProvider } from './context/context';
import theme from './themes/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router>
          <div className="App">
            <Routes />
          </div>
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
