import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const recognizeUser = async (photo: File, userId: string, clientId: string): Promise<{
	success: boolean;
	message: string;
	status: any;
	data: any; url_redireccion: string
}> => {
	try {
		const formData = new FormData();
		formData.append('photo', photo as Blob);
		formData.append('userId', userId);
		formData.append('clientId', clientId);

		const response = await axios.post(`${API_URL}users/recognize`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		return response.data;
	} catch (error: any) {
		throw error?.response;
	}
};

export const sessionValidate = async (t: string) => {
    try {
        const validate = await axios.post(`${API_URL}sessions/validate`, {}, {
			headers: {
				'Authorization': t
			}
        });
        return validate?.data;
    } catch (error: any) {
        throw new Error(error);
    }
}
