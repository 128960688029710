
import { Route, Routes } from 'react-router-dom';
import RecognitionScreen from 'pages/Recognition/RecognitionScreen';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RecognitionScreen/>} />
      <Route path="/" element={<RecognitionScreen/>} />
      <Route path="/reconocimiento" element={<RecognitionScreen/>} />      
    </Routes>
  );
}

export default AppRoutes;
